/***************** CSS RESET *****************/
/* CSS reset taken from https://www.joshwcomeau.com/css/custom-css-reset/ */

/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  5. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

/*
  6. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}

/*
  7. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/*
  8. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

/*
  Suppress button flash on tap
*/
button, a, div {
  -webkit-tap-highlight-color: transparent;
  padding-block: 0;
  padding-inline: 0;
}

/*
  Suppress all focus borders
*/
*:focus {
  outline: none;
}


/***************** FONTS *****************/

@font-face {
  font-family: Jost-Regular;
  src: url('client/fonts/Jost-Regular.woff2');
  font-display: fallback; /* see https://courses.joshwcomeau.com/css-for-js/06-typography-and-media/09-font-display */
}

@font-face {
  font-family: Jost-SemiBold;
  font-weight: 600;
  src: url('client/fonts/Jost-SemiBold.woff2');
  font-display: fallback; /* see https://courses.joshwcomeau.com/css-for-js/06-typography-and-media/09-font-display */
}

::backdrop {
  background-color: var(--color-background-modal-overlay)
}

input {
  @media (prefers-color-scheme: dark) {
    color-scheme: dark;
  }
}
:root {
  /***************** COLORS *****************/

  /* We plan to use LCH() color because it is the most natural to tweak w/re to the human eye
     See this article for details: https://atmos.style/blog/lch-color-space */

  --color-nightingale: lch(26.5% 97 307); /* official Sing Your Part color from style guide */
  --color-nightingale-med: #9240FF;
  --color-nightingale-light: lch(60% 97 307);

  --color-text: black;
  --color-trash: red;
  --color-text-light: lch(60 0 300);
  --color-text-lighter: lch(80 0 300);
  --color-text-darker: lch(50 0 300);
  --color-text-search-placeholder: lch(60 0 300);
  --color-text-button: white;
  --color-text-button-onboarding: var(--color-nightingale);
  --color-text-button-onboarding-primary: white;
  --color-text-singing-plan-callout: var(--color-nightingale);
  --color-background: white;
  --color-background-template: lch(95 0 0); /* for offsetting white boxes */
  --color-background-modal-overlay: lch(0 0 0 / 0.5);
  --color-background-search: lch(93% 1 285);
  --color-background-button: lch(60 0 300);
  --color-primary-button: var(--color-nightingale);
  --color-background-button-onboarding: var(--color-primary-button);
  --color-background-button-disabled: lch(85 0 300);
  --color-background-selected-item: lch(90 0 0);
  --color-background-event: lch(90 0 0);
  --color-background-table-banding: var(--color-background-search);
  --color-liturgy-row-header: var(--color-background-search);
  --color-input-border: lch(50 0 300);
  --color-slider: black;
  --color-slider-thumb: white;
  --color-slider-thumb-border: var(--color-text);
  --color-checkbox-selected: var(--color-nightingale-light);
  --color-checkbox-unselected: lch(75 0 0);
  --color-checkmark-song: #4DC502;
  --color-checkmark-song-purple: var(--color-nightingale);
  --color-button-border: lch(72 0 270);
  --color-button-border-dark: lch(40 0 270);
  --color-button-selected-background: lch(95 8 244);
  --color-button-selected-border: lch(82 26 246);
  --color-progress-bar-background: lch(85 0 300);
  --color-progress-bar-fill: var(--color-nightingale);
  --color-progress-bar-shine: var(--color-nightingale-med);
  --color-spinner: var(--color-nightingale);
  --color-text-custom-music-placeholder: lch(70 0 300);
  --color-date-highlight-background: var(--color-nightingale);
  --color-date-highlight-text: var(--color-nightingale);
  --color-pdf-upload: red;
  --color-warning: #E9D502;

  /***************** DARK MODE *****************/

  @media (prefers-color-scheme: dark) {
    --color-text: white;
    /*--color-trash: <dark mode same as light>*/
    --color-text-light: lch(60 0 300);
    --color-text-lighter: lch(80 0 300);
    --color-text-darker: lch(60 0 300);
    /*--color-text-search-placeholder: <dark mode same as light>*/
    --color-text-button: black;
    --color-text-button-onboarding: white;
    --color-text-button-onboarding-primary: black;
    --color-text-singing-plan-callout: var(--color-nightingale-light);
    --color-background: black;
    --color-background-template: lch(25 0 0); /* for offsetting white boxes */
    --color-background-modal-overlay: lch(75 0 0 / 0.5);
    --color-background-search: lch(25% 1 285);
    --color-background-button: lch(75 0 300);
    --color-primary-button: var(--color-nightingale-light);
    --color-background-button-onboarding: var(--color-primary-button);
    --color-background-button-disabled: lch(30 0 300);
    --color-background-selected-item: lch(35 0 0);
    --color-background-event: lch(35 0 0);
    /*--color-background-table-banding: <dark mode same as light>*/
    /*--color-liturgy-row-header: <dark mode same as light>*/
    --color-input-border: lch(60 0 300);
    --color-slider: var(--color-text-light);
    --color-slider-thumb: var(--color-slider);
    --color-slider-thumb-border: var(--color-text);
    --color-checkbox-selected: var(--color-nightingale);
    --color-checkbox-unselected: lch(60 0 0);
    /*--color-checkmark-song: <dark mode same as light>*/
    /*--color-checkmark-song-purple: <dark mode same as light>*/
    /*--color-button-border: <dark mode same as light>*/
    /*--color-button-border-dark: <dark mode same as light>*/
    --color-button-selected-background: lch(50 8 244);
    --color-button-selected-border: lch(60 26 246);
    /*--color-progress-bar-background: <dark mode same as light>*/
    /*--color-progress-bar-fill: <dark mode same as light>*/
    /*--color-progress-bar-shine: <dark mode same as light>*/
    --color-spinner: var(--color-nightingale-light);
    --color-text-custom-music-placeholder: lch(50 0 300);
    /*--color-date-highlight-background: <dark mode same as light>*/
    --color-date-highlight-text: var(--color-nightingale-light);
    /*--color-pdf-upload: <dark mode same as light>*/
  }
}

body {
  font-family: Jost-Regular, Arial, sans-serif;
  color: var(--color-text);
  background-color: var(--color-background);

  /*
  this line eliminates swipe navigation for at least on Firefox
  overscroll-behavior-x: none;
  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow: hidden;
  /*
  overscroll-behavior-x: none;
  */
}

input {
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
}
